$enable-shadows: true;

$theme-colors: (
  primary: #0033cc,
  secondary: #dddddd,
  success: #73f3c3,
  info: #fdf3b5,
  warning:  #ff9379,
  danger: #bf5329,
  light: #ffffff,
  dark: #343a40
);

/* Typography */
$icon-font-path: "~bootstrap-sass/assets/fonts/bootstrap/";
$font-family-sans-serif: hkgrotesk, Arial, sans-serif;
$line-height-base: 1.4;
$text-color: #000;

$h1-font-size: 1rem * 1.5625; // 25px
$h2-font-size: 1rem * 1.5;  // 24px => wirkt beinahe gleich gross wie H1 mit anderem Fontschnitt
$h3-font-size: 1rem * 1.5; 
$h4-font-size: 1rem * 1.5; 

/* Buttons */
$btn-default-color: $text-color;
$btn-font-weight: 500;

/* Inputs */
$input-border: lighten($text-color, 40%);
$input-border-focus: lighten(map-get($theme-colors, 'primary'), 25%);
$input-color-placeholder: lighten($text-color, 30%);

/* Panels */
$panel-default-heading-bg: #fff;

/* Breadcrumb */
$breadcrumb-active-color: $text-color;
$breadcrumb-bg: transparent;

/* Alerts */
$alert-padding: 10px;

/* Modal */
$modal-lg: 700px;