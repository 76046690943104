@font-face {
    font-family: 'hkgrotesk';
    src: url('../fonts/hkgrotesk-light-webfont.woff2') format('woff2'),
         url('../fonts/hkgrotesk-light-webfont.woff') format('woff');
    font-weight: 300;
    font-style: normal;
}

@font-face {
    font-family: 'hkgrotesk';
    src: url('../fonts/hkgrotesk-lightitalic-webfont.woff2') format('woff2'),
         url('../fonts/hkgrotesk-lightitalic-webfont.woff') format('woff');
    font-weight: 300;
    font-style: italic;
}

@font-face {
    font-family: 'hkgrotesk';
    src: url('../fonts/hkgrotesk-italic-webfont.eot');
    src: url('../fonts/hkgrotesk-italic-webfont.eot?#iefix') format('embedded-opentype'),
         url('../fonts/hkgrotesk-italic-webfont.woff2') format('woff2'),
         url('../fonts/hkgrotesk-italic-webfont.woff') format('woff'),
         url('../fonts/hkgrotesk-italic-webfont.ttf') format('truetype');
    font-weight: 400;
    font-style: italic;
}

@font-face {
    font-family: 'hkgrotesk';
    src: url('../fonts/hkgrotesk-regular-webfont.eot');
    src: url('../fonts/hkgrotesk-regular-webfont.eot?#iefix') format('embedded-opentype'),
         url('../fonts/hkgrotesk-regular-webfont.woff2') format('woff2'),
         url('../fonts/hkgrotesk-regular-webfont.woff') format('woff'),
         url('../fonts/hkgrotesk-regular-webfont.ttf') format('truetype');
    font-weight: 400;
    font-style: normal;
}

@font-face {
    font-family: 'hkgrotesk';
    src: url('../fonts/hkgrotesk-medium-webfont.woff2') format('woff2'),
         url('../fonts/hkgrotesk-medium-webfont.woff') format('woff');
    font-weight: 500;
    font-style: normal;
}

@font-face {
    font-family: 'hkgrotesk';
    src: url('../fonts/hkgrotesk-mediumitalic-webfont.woff2') format('woff2'),
         url('../fonts/hkgrotesk-mediumitalic-webfont.woff') format('woff');
    font-weight: 500;
    font-style: italic;
}

@font-face {
    font-family: 'hkgrotesk';
    src: url('../fonts/hkgrotesk-semibold-webfont.eot');
    src: url('../fonts/hkgrotesk-semibold-webfont.eot?#iefix') format('embedded-opentype'),
         url('../fonts/hkgrotesk-semibold-webfont.woff2') format('woff2'),
         url('../fonts/hkgrotesk-semibold-webfont.woff') format('woff'),
         url('../fonts/hkgrotesk-semibold-webfont.ttf') format('truetype');
    font-weight: 700;
    font-style: normal;
}

@font-face {
    font-family: 'hkgrotesk';
    src: url('../fonts/hkgrotesk-semibolditalic-webfont.eot');
    src: url('../fonts/hkgrotesk-semibolditalic-webfont.eot?#iefix') format('embedded-opentype'),
         url('../fonts/hkgrotesk-semibolditalic-webfont.woff2') format('woff2'),
         url('../fonts/hkgrotesk-semibolditalic-webfont.woff') format('woff'),
         url('../fonts/hkgrotesk-semibolditalic-webfont.ttf') format('truetype');
    font-weight: 700;
    font-style: italic;
}

@font-face {
    font-family: 'hkgrotesk';
    src: url('../fonts/hkgrotesk-bold-webfont.eot');
    src: url('../fonts/hkgrotesk-bold-webfont.eot?#iefix') format('embedded-opentype'),
         url('../fonts/hkgrotesk-bold-webfont.woff2') format('woff2'),
         url('../fonts/hkgrotesk-bold-webfont.woff') format('woff'),
         url('../fonts/hkgrotesk-bold-webfont.ttf') format('truetype');
    font-weight: 900;
    font-style: normal;
}