html, body     { font-weight: 400; height: 100vh; width: 100%; }
body           { font-size:$font-size-standard; display:flex; min-height:100vh; flex-direction:column; }
body.mce-content-body { padding:10px; }

p {
  hyphens: manual !important;
}

.h1, .h2, .h3, .h4, .h5, .h6, h1, h2, h3, h4, h5, h6 { font-weight:400; }
.font-size-sm, #intro .font-size-sm, small  { font-size:$font-size-sm; }
.fw300         { font-weight: 300; } 
.fw400         { font-weight: 400; } 
.fw500         { font-weight: 500; } 
.fw700         { font-weight: 700; } 
hr             { border-color:#000; }
p              { hyphens: auto; letter-spacing:0.035rem; }
blockquote     { border-left:6px solid #ccc; padding-left:12px; margin:2rem; font-family:Times New Roman, serif; }
blockquote p   { margin-bottom:0; }
.fullwidth-image blockquote { margin:.5rem 0 1.15rem; }
b, strong      { font-weight:700; }

.bg-adfontes   { background-color:$bg-adfontes; min-height:calc(100vh - 160px); }
#intro.bg-adfontes { min-height:auto; }
.bg-grey, main.home
               { background-color:$bg-grey; }
#suche         { min-height:calc(100vh - 160px); }
.bg-recherche  { background-color:$bg-recherche; min-height:calc(100vh - 160px); }
.bg-training   { background-color:$bg-training; min-height:calc(100vh - 160px); }
.bg-ressourcen { background-color:$bg-ressourcen; min-height:calc(100vh - 160px); }
.bg-tutorium   { background-color:$bg-tutorium; min-height:calc(100vh - 160px); }
#logo          { width:155px; padding:12px 0 18px; margin-left:0; float:left; }

section        { padding:20px 0; }
#intro h1,
h1.profile     { margin-top:36px; margin-bottom:20px; }
h1             { font-weight:400; }
h1.intro       { font-size: 2.75rem; font-weight:400; margin-top:36px; }
h1.profile     { font-size: 2rem; }
h2, h3, h4     { font-weight:400; }

a.pw           { position:relative;  padding:0; margin-left:24px; width:100%;     
                 color:#909090; text-decoration:none; border:0; }
a.pw:hover     { border:0; }
a.pw::before   { position:absolute; top:1px; left:-24px; width:15px; height:15px; }
a.pw.plus::before  { content: url(/img/plus-i.svg); }
a.pw.plus:hover::before  { content: url(/img/plus-a.svg); }
a.pw.minus:hover::before  { content: url(/img/minus-a.svg); }
a.pw.minus::before  { content: url(/img/minus-i.svg);  }
#pw_change     { margin:24px 0 7px; }
#pw_fields     { display:none; }

audio, video   { width:445px; max-width:100%; margin-bottom:.75rem; }
audio          { display:block; }
video          { height:auto; }
.video iframe, .video embed,.video object {
                 max-width: 100%; }
.add           { font-size:$font-size-xlg; font-weight:300; hyphens:none; margin-bottom:55px; }

.site {
    h3         { font-weight:400; }
    h4         { margin-bottom:0; }
    .ref-link + h4 { margin-top:16px; }
}

td, th         { padding:.125rem .7rem .125rem 0; vertical-align:top; border-bottom:0; border-top:0; }
.table {
    tbody tr:hover { background:#fff; }
    td, th         { padding:0.35rem; vertical-align: baseline; border-bottom:1px solid #000; border-top:0; }
    thead th       { border-bottom:2px solid #000; font-weight: 400; }
    td .next       { float:right; padding-top:3px; }
}
.table.ostertermine {
  td     { border:0; }
  tr:hover { background:transparent; }
}
.table.table-bordered {
    tbody tr:hover { background:none; }
    td         { border:1px solid #ccc; }
    th         { border:0; padding:0; }
    thead th   { border-bottom:inherit; }
}

.mce-item-table   { width:auto; 
  td { width:auto; }
}
.table-fullwidth, .table-fullwidth.mce-item-table { width:100%; }

td.table-bordered { border:1px solid #ccc; }
td.table-tb-bordered { border:0; border-top:1px solid #ccc; border-bottom:1px solid #ccc; }

table.highlight {
  td.table-highlight-horizontal, td.table-highlight-vertical, td.grey:hover
    { background: rgba(115, 243, 194, .2); cursor:auto; }
  
  td:hover 
    { background: rgba(115, 243, 194, 1); cursor:pointer; }

  td[colspan].grey, td[rowspan].grey
    { background: #eee !important; }
}

.btn           { padding:.275rem .75rem .5rem; letter-spacing:0.01rem; }
.btn-dark:hover { color:map-get($theme-colors, 'success') !important; }
.btn-dark.btn:focus { color:#fff; }
.white         { background:#fff; }
.grey          { background:#eee; }
.box           { padding:15px 0; }
.white h3      { margin-bottom:1rem; }
.white.miradorbox       { padding:0; }
.mirador.c1.compo    { height:300px; position:relative; }
.mirador.c1    { height:400px; position:relative; }
.mirador.c2    { height:450px; width:100%; position:relative; }
.mirador.draganddrop_left { height:650px; width:100%; position:relative; }
.mirador.fullwidth { height:180px; width:100%; position:relative; }
.mirador.fullwidth.fullheight { height:500px; }
.white.miradorbox.c1_2 .cntnt { margin:0; }
.mirador-container .qtip-bootstrap { max-height:none !important; height:auto !important;border:1px solid #ccc !important; }
.mirador-container .qtip-viewer .qtip-content { max-height:none !important;}
.gruen { color:#007300; } 
.blau { color:#002688; } 
.rot { color:#A40000; } 
.box + .box    { padding:0 0 25px; }
.msg.box { padding-bottom:12px; }
.box.btop + .box.btop 
               { padding:15px 0; }
.alert         { padding:10px 40px; width:100%; margin:0 15px; text-align:center; font-weight:700; }
.alert + .alert
               { margin-top:5px; }
.alert ul      { margin-bottom:0.25rem; }
.alert.info li,
.alert.success li
               { list-style-type:none; }
.alert.warning { text-align:left; }
.alert-sm      { padding:5px 40px; margin:0 15px 15px; }
.alert-sm > p  { font-size:$font-size-sm !important; margin:0; }
.alert.exercise_msg, .alert.exercise_feedback { font-size:1.1875rem; }
.success       { background-color:map-get($theme-colors, 'success'); }
.info          { background-color:map-get($theme-colors, 'info'); }
.info p        { margin:0; }
.warning       { background-color:map-get($theme-colors, 'warning'); }
.pr-10         { padding-right: 10px; }
.pl-0          { padding-left: 0; }
.pb-15         { padding-bottom:15px; }
.btop          { border-top:1px solid #000; margin-top:15px;}
.ptb-10        { padding-top: 10px; padding-bottom: 10px; }
.ptb-65        { padding-top: 65px; padding-bottom: 10px; }

.dot           { height: 6px; width: 6px; border:1px solid #666; background:#fff;
                 border-radius: 50%; margin:0 2px 2px 0; display: inline-block; }
.full          { background:#000; }

.full-height   { height: 100vh; }
.flex-center   { align-items: center; display: flex; justify-content: center; }

.position-ref {
    position: relative;
}

.top-right {
    position: absolute;
    right: 10px;
    top: 18px;
}

.content {
    text-align: center;
}

.title {
    font-size: 84px;
    margin-top: 200px;
}

.links > a {
    padding: 0 25px;
    letter-spacing: .1rem;
    text-decoration: none;
}

#navigation         { z-index:6; float:right; margin:0 40px 0 0; position:relative;
                      background-color: #fff; }

#mainnav {
    z-index:10;
    ul#firstlevel          { z-index:110; font-size:$font-size-mainnav; margin:56px 0 0; 
                             padding-left:58px; }
    li.mainnav-li          { height:47px; float:left; display:block; padding-left:24px; }
    li.mainnav-li:first-of-type { padding-left:0; }
    a                      { color:#000; letter-spacing:0.025em; }
    a:hover, li.active > a, li.currentmain > a,
    .subnav li.current a,
    .subnav li li.current a,
    .subnav li.current li a:hover   { font-weight:700; text-decoration:none; letter-spacing:0.015em; }
    li.mainnav-li.currentmain > a, 
    li.mainnav-li.active > a { letter-spacing:0.0175em; }
    li.mainnav-li > a::after { display:block; content:attr(title); font-weight:700;
                             height:0; overflow:hidden; visibility:hidden; letter-spacing:0.0175em;}
    span                   { display:block; background-color:#efefef; width:24px; height:6px;
                             margin-bottom:5px; border-radius:3px; cursor:pointer; }
    span:hover, span.here  { background-color:#2991af; }
    .subnav                { z-index:99; display:none; position:absolute; left:48px; top:103px; right:-14px;
                             padding:0 14px 0 10px; margin-bottom:0; background-color:#fff; border-top:$header-border;
                             overflow-y: auto; } 
    .collapsed ul          { display:none; }
    .subnav li             { display:block; padding:12px 0 0; }
    .subnav li.current li a { font-weight:400; letter-spacing:0.025em; }
    .subnav li::after      { }
    .subnav .plusminus     { margin:3px 0 0 10px; width:20px; height:20px; float:right; }
    .subnav span           { position:absolute; left:-32px; margin-top:10px;
                             background-color:#2991af;}
    .subnav li ul          { padding-left:18px; }
    .subnav li ul li       { font-size:$font-size-seventeen !important; padding:6px 0 0; }
    .subnav > li:last-of-type { padding-bottom:20px; }
}

#servicenav {
    position:absolute; top:9px; right:0; left:0; z-index:100; display:none;
    ul                    { float:right; /*line-height:0;*/ margin-bottom:0; padding-left:0;
                            margin-top:-4px; }
    ul li                 { display:inline-block; padding:0; height:20px; }
    ul li:first-of-type   { border-left:0; padding:0 18px 0 0; }
    ul li:after           { padding-left:3px; }
    a, #profile-filter, #language-switch    { font-size:$font-size-sm; color:#000; }
    #profile-filter       { float:left; width:200px; }
    #language-switch      { width:54px; display:inline-block;
                            .dropdown-menu { min-width:auto; margin-top:1px; padding:6px 0 4px 10px; border:0; 
                                             width:46px; z-index:200; border: 1px solid rgba(0,0,0,.15); }
                            a              { display:block; }
                            a:hover        { text-decoration:none; }
                            .active, .dropdown-menu a:hover { color:#999; }
                            .dropdown-menu a { padding-bottom:2px; }
                          }
    #language-switch.show { display:inline-block !important; }
    #profile-filter      { 
                            margin-left:55px; height:20px;
                            
                            button, .dropdown-menu {
                              font-size:$font-size-sm; color:#666; padding:1px 4px 1px 3px;
                               background-color:#fff; text-align:left;
                               width:98%; font-weight:400;
                            }
                            .dropdown-menu { margin-top:1px; padding:4px 0 8px 3px;  }
                            #profile-filter-button {
                                padding-left:6px; width:196px;
                            }
                            .dropdown-item { padding-bottom:4px; }
                            button::after { position:absolute; right:11px; top:11px; }
                            .dropdown-menu { z-index:200; }
                            .active, .dropdown-menu button:hover { font-weight:700;letter-spacing:-0.008em; }
                          }

    li img                { vertical-align:text-top; }
    li:not(:first-child):not(:last-child)::after 
                          { font-size:16px; color:#cdcdcd; content: "|"; }
    #search, #help, 
    #glossary             { display:inline-block; width:20px; height:20px;
                            vertical-align:middle; }
    #help:focus           { outline:none; }
    #search               { margin-right:2px;}
    #help                 { margin-right:-1px; }
    #glossary             { width:35px; }
    #search:hover img, #search.active img,
    #help:hover img, 
    #glossary:hover img,
    #glossary.active img     { display:none; }
    #search:hover::before, #search.active::before   { content:url(/img/zoom-a.svg); }
    #help:hover::before     { content:url(/img/help-a.svg); }
    #glossary:hover::before,
    #glossary.active::before { content:url(/img/glossary-a.svg); }
    a#login:hover, a#user:hover
                            { text-decoration:none; }
    a#login:focus, a#user:focus
                            { outline:none; }
    a#user                  { margin-right:1px; margin-left:2px; }
    a#user:hover            { border-bottom:1px solid #000; }
    #login img, #logout img { margin-top:1px; }
    #login-txt              { margin-left:2px; }
}


header { background-color:#fff; height:105px; border-bottom:$header-border; z-index:5;
         div                    { position:relative; }
         #dark_overlay          { display:none; position:fixed; top:0; left:0; right:0; bottom:0; 
                                  background:rgba(0,0,0,0.3); z-index:1; }
         #white_overlay_top     { height:105px; position:absolute; right:0; top:0; width:10vw; z-index:6;
                                  border-bottom:$header-border; background-color:#fff; display:none; }
         #white_overlay_content { height:200px; position:absolute; right:0; top:105px; z-index:5;
                                  width:10vw; background-color:#fff; display:none; }
         #white_stripe          { height:100vh; position:absolute; top:0; left:0; width:48px; 
                                  z-index:1; background:#fff; display:none; }
       }

section, .modal, .mce-content-body {
        a        { color:#000; border-bottom:1px solid #909090; }
        a:hover  { color:#000; border-bottom:1px solid #000; text-decoration:none; } 
}
section:last-child { padding-bottom:45px; }

main   { 
  display:block; z-index:3; flex:1; 
}

.stabilo { background-color:rgba(120, 120, 120, .1);  }

.glossarlink, .externallink { color:#000; cursor:pointer;
               margin-right:25px; border-bottom:1px solid #909090;  }  
.externallink {  margin-right:20px; }
.glossarlink::after { content:url(/img/glossarlink.svg); 
                      width:20px;
                      height:auto; vertical-align:top; 
                      display:inline-block;position: absolute;
                      margin-left:3px; margin-top:1px;}
.externallink::after { content:url(/img/externallink.svg); 
                      width:16px;
                      height:auto; vertical-align:top; 
                      display:inline-block; position:absolute;
                      margin-left:3px;}
.glossarlink:hover  { border-bottom:1px solid #000; }
.font-size-sm .glossarlink { margin-right:22px; }
.font-size-sm .glossarlink::after { width:18px; margin-left:2px; }
.font-size-sm .externallink { margin-right:16px; }
.font-size-sm .externallink::after { width:12px; margin-left:2px; }

.ziplink, .pdflink { position:relative; color:#000; cursor:pointer; white-space:nowrap;
                     margin-left:1.5625rem; border-bottom:1px solid #909090;  }  
.ziplink::before, .pdflink::before { 
                      width:1.25rem; top:3px;
                      height:auto; vertical-align:top; 
                      display:inline-block;position: absolute;
                      left:-1.5625rem;}
.ziplink::before { content:url(/img/zip.svg); }
.pdflink::before { content:url(/img/pdf.svg); }

#circle {
    position:relative; width:100px; height:100px;
    margin:0 auto 12px; background-color:#f30;
    border-radius:50%;
    font-size:16px; color:#fff; text-align:center;    
    span {
        position:absolute; left:50%; top:45%; width:100%;
        text-align:center;
        -ms-transform: translate(-50%, -45%);
        -webkit-transform: translate(-50%, -45%);
        transform: translate(-50%, -45%);
    }
    a { color:#fff; border:0; }
    a:hover { color:#ddd; }
}

#news {
    h2                  { font-size: $font-size-standard; font-weight:400;
                          margin:0 0 10px 10px; }
    #rss_link           { float:right; border:0; margin:0 16px 0 0; }
    #rss_link i         { font-weight:400 !important; }
    h3                  { font-size: $font-size-xlg-bold; font-weight:500; }
    #accordion          { border-top:2px solid #808080; margin:15px 0; }
    .card               { background:transparent; border-bottom:1px solid #808080; }
    .card-header        { background:transparent; border:0;  padding: .75rem 0; }
    .card-header a      { border:0; display:flex; width:100% }
    .card-block         { padding:.25rem 0 0; }
    .card-block p:last-of-type { margin-bottom:4.5rem; }
}

#acc {
    h3                  { font-size: $font-size-xlg-bold; font-weight:400; }
    #accordion          { border-top:2px solid #808080; margin:15px 0; }
    .card               { background:transparent; border-bottom:1px solid #808080; }
    .card-header        { background:transparent; border:0; padding: .75rem 0 .25rem 0; }
    .card-header a      { border:0; display:flex; width:100% }
    .card-block         { padding: .25rem 0 0; margin:0; }
    .row.collapse.show  { margin-bottom:3.5rem; }
}

#loginModal .modal-dialog, #glossaryItemModal .modal-dialog, #aaiModal .modal-dialog {
    -webkit-transform: translate(0,-50%);
    -o-transform: translate(0,-50%);
    transform: translate(0,-50%);
    top: 50%;
    margin: 0 auto;
}

#subject, label[for="subject"] { display: none; visibility: hidden; }

.cntnt img, .white.box img, .card-block img  { max-width:100%; height:auto; }
.white.box .btn { margin-bottom:.5rem; }

.modal {
  .modal-content      { padding:0 5px; }
  .modal-body         { padding:35px 0 20px; }
  .modal-header       { padding:20px 0; border-bottom: 1px solid #000;; }
  .spacer15           { margin-top:15px; }
  .pt30               { padding-top: 30px; }
  .aai-label          { margin-bottom:0.25rem; }
  h2                  { font-size: $font-size-xlg-bold; font-weight:400; }
  p, .successtxt      { font-size: $font-size-seventeen; } 
  hr                  { margin:30px 0; }
  p.small              { margin-bottom:0; font-size:$font-size-sm; }
  p.kursiv             { font-style:italic; }
  input[type="radio"] { margin-bottom:.75rem; }
  input[type="password"],
  input[type="text"]  { background:#eee; border-radius:0; border-bottom:1px solid #000; 
                        padding:.375rem .375rem .4rem; display: block; float: right;}
  input[type="password"]:focus,
  input[type="text"]:focus  { border:1px solid #000; background:#fff; box-shadow:none; }
  #profileform div.ipt { margin-bottom:3px; }
  #privacy_policy_accepted, #privacy_policy_accepted_aai { float:left; margin:5px 9px 20px 0; }
  div.ipt label       { padding:0; margin-bottom:0; font-size:.75rem; line-height:1.4;  }
  div.ipt label.opt   { font-size:1rem; }
  div.ipt label.opt.ttl { font-size:.75rem; display:block; }
  #wayf_submit_button { border:0 !important; font-size: 14px; background:url(/img/right-i.svg) no-repeat  0 0; width:24px; 
                        height:24px; margin:10px 0 9px !important; }
  #wayf_submit_button:hover     { background: url(/img/right-a.svg) no-repeat; }
  #wayf_intro_label   { font-size:16px !important; margin:3px 0 0 3px !important; }
  #user_idp           { margin:12px 0 3px !important; }
  #wayf_remember_checkbox_div { margin-left:2px; }
  .login-go           { margin:5px 0 0; background:url(/img/right-i.svg) no-repeat  0 0; width:24px; height:24px;}
  .login-go:hover     { background: url(/img/right-a.svg) no-repeat; }
  #changePasswordform .login-go { margin-top:33px; }
  #profileform, 
  #pwforgottenform    { display:none; }
  #profile_submit     { margin-top:12px; }
  .modal-header button.close:focus, input.login-go:focus { outline:none; }
  #login_feedback, #aai_feedback { display:none; }
  #loginform          { margin-top:12px; }
}

.error                { color:map-get($theme-colors, 'warning'); }
.successtxt           { color:#63d3a9; }
#login-feedback { padding-top:12px; }
.okay          { display:inline-block; width:12px; border-radius:50%; height:12px; margin:11px 0 3px 14px; cursor:pointer; 
                 background-color:map-get($theme-colors, 'success'); }

.plusminus          { margin:1px 30px 0 0; width:24px; height:24px; }
.plusminus::before  { content: url(/img/minus-i.svg);  }
.plusminus:hover::before  { content: url(/img/minus-a.svg); }
.collapsed .plusminus::before { content: url(/img/plus-i.svg); }
.collapsed .plusminus:hover::before { content: url(/img/plus-a.svg); }
.rss                { margin:0; width:24px; height:24px; }
.rss::before        { content: url(/img/rss-i.svg);  }
.rss:hover::before  { content: url(/img/rss-a.svg); }

.prev, .next        { display:block; float:left; margin:0 8px; width:20px; height:20px; }
.go                 { margin:2px 0 0; width:24px; height:24px; cursor:pointer; }
.go::before, .next::before { content: url(/img/right-a.svg);  }
.go:hover::before, .next:hover::before { content: url(/img/right-a.svg); }
.prev::before       { content: url(/img/left-i.svg); }
.prev:hover::before { content: url(/img/left-a.svg); }

.breadcrumb         { 
  font-size:$font-size-sm; padding:0; 
  .breadcrumb-item.active:last-of-type::after { content:""; display:none; padding-right:0; padding-left:0;  }
  .breadcrumb-item:last-of-type::after,
  span::after        { display:inline-block; padding-right:.5rem; padding-left:.5rem; 
                      color:#868e96; content:"/"; }       
}
.linklist           { margin-top:3.5rem; }
.card               { border:0; border-radius:0; }
#suche .card        { margin-bottom:6px; }
.card-body          { padding:10px 10px 20px; }
#suche .card-body   { padding:10px; }
.card.refs h4, .linklist h4       { margin-bottom:0; margin-top:16px; }
.card.refs h4:first-of-type, .linklist h4:first-of-type { margin-top:0; }
a.ref-link          { font-size:$font-size-sm; position:relative;  padding:0; margin-left:24px; }
a.ref-link::before  { position:absolute; top:0; bottom:-2px; left:-24px; width:20px; content:url(/img/right-i.svg); padding:2px; }
a.ref-link:hover::before  
                    { content:url(/img/right-a.svg); }
a.mail, a.facebook, a.twitter
                    { position:relative; display:inline-block; width:20px; height:20px; border:0; margin:0 5px; }
a.mail::before              { content:url(/img/mail-i.svg); margin:1px 3px 0; }
a.mail:hover::before        { content:url(/img/mail-a.svg); border:0; }
a.twitter::before           { content:url(/img/twitter-i.svg); }
a.twitter:hover::before     { content:url(/img/twitter-a.svg); border:0; }
a.facebook::before          { content:url(/img/facebook-i.svg); margin:0 3px; }
a.facebook:hover::before    { content:url(/img/facebook-a.svg); border:0; }
a.facebook:hover, a.twitter:hover, a.mail:hover { border:0; }

#flash_profile_frontend .alert { margin:0; font-size:1rem; }

.figure-caption, 
.cntnt img[alt]            { font-size: $font-size-sm; color:#000; display:block; margin-top:0.25rem; }
#glossar a          { border-bottom:0; }
#glossar a:hover, #glossar a:focus    { font-weight:700; letter-spacing:-.015rem; }
#glossar a:focus    { outline:none; }

.gnav         { list-style:none; display:inline; margin:0; padding:0;
    li        { display:inline-block; padding:0; }
    li a      { display:inline-block; border:0; border-radius:50%; width:28px; height:28px; 
                font-weight:500; text-align:center; vertical-align: middle; }
    li a:hover, li.current a, li a:focus
              { background: #fff; }
    li a.all_letters      { width:120%; border-radius:14px; }
}
#glossar li a.all_letters:hover { letter-spacing:0; }

.vpager       { list-style:none; display:inline;
    li        { background-color:#fff; margin-left:5px; }
    li, li a  { display:inline-block; width:6px; height:14px; border-radius:3px; line-height:0; }
    li.do, li.do a { height:20px; }
    li a      { border:0; }
    li.solved { background-color:#73f3c3; }
    li.current { background-color:#000; }
}

.tpager        { float:right; position:relative; margin:2px 0 0 15px; width:110px; text-align:center;
    span       { font-size:$font-size-seventeen; display:block; margin-top:0;  }
    .next      { position:absolute; top:1px; right:0; margin:0 0 0 5px; }
    .prev      { position:absolute; top:1px; left:0; margin:0 5px 0 0; }
    .prev.neutral::before, .prev.neutral:hover::before  { content: url(/img/left-n.svg);  }
    .next.neutral::before, .next.neutral:hover::before  { content: url(/img/right-n.svg);  }
    .next::before { content: url(/img/right-i.svg);  }
    .next:hover::before { content: url(/img/right-a.svg); }
}

.citation {
  border:1px solid #999;
  padding:2rem;
  margin-top:2.25rem;
  a { font-size:1rem; hyphens:none; }
  li { margin-bottom:0.5rem}
  p:first-of-type { margin-bottom:.8rem; }
}

.all-contenttables tbody tr { cursor:pointer; }
#transkription_input.work { border:0; min-height:160px; }
textarea.autogrow-field {
  overflow: hidden;
  resize: none;
}
textarea.autogrow-field ~ .autogrow-mirror {
  position: absolute;
  visibility: hidden;
  white-space: normal;
  word-break: break-word;
  z-index: -1;
}
#transcription_status_msg, .exercise_feedback.alert.warning { text-align:center; }
#transcription_status_msg ul, .exercise_feedback ul { margin-top:9px; margin-bottom:12px; text-align:left; font-weight:400; font-size:1rem; }
#transcription_status_msg ul span, .exercise_feedback ul span { font-weight:700; }
#transcription_status_msg p, .exercise_feedback p { font-weight:700; font-size:1.1875rem; margin-bottom:0; }
.note-editor.note-frame.card { border-radius:4px; }
.note-editor.note-frame .note-editing-area .note-editable p { margin-bottom:0;hyphens:none; }
.note-editor.note-frame .note-editing-area .note-editable p span.falsch { background-color:#f7d8cb; }
h3.transcription_h3 { margin-top:34px; }
#special_chars_help div { display:inline-block; padding:2px 6px; border:1px solid #ddd; margin:0 3px 3px 0; cursor:pointer; 
                          font-size:17px; border-radius:6px; }
#special_chars_help div:hover { background-color:#efefef; }
.exercise_feedback.hidden { display:none; }

.work         { width:100%; background:#eee; padding:10px; border-radius:4px;
    .choice input
              { margin:.3rem 0 0 -32px; }
    .table input 
              { margin:.3rem 0 0; }
    .choice .form-check-label
              { padding-left: 0; vertical-align:top; cursor:pointer; }
    label,legend { font-size:$font-size-seventeen; }
    .textinput label  { margin:0.675rem 0 0; display:block; font-size:$font-size-sm; }
    .textinput label.inln { display:inline-block; margin-right:.75rem; margin-top:0; font-size:$font-size-seventeen; }
    .textinput label.inln input { margin-bottom:.375rem; }
    .textinput label.inln input.form-check-input { position:relative; margin-left:0; }
    .textinput.mx label.mc  { margin:0; display:inline-block; font-size:$font-size-seventeen; }
    legend { padding-left:2px; margin-bottom:.25rem; }
    legend.mc-sel { margin-bottom:0; font-size:1rem; }
    .textinput legend + label { margin-top:4px; }
    fieldset { margin-bottom:1rem; }
    fieldset.textinput { margin-bottom:1.75rem; }
    fieldset:last-of-type { margin-bottom:0; }
    fieldset.textinput:last-of-type { margin-bottom:.375rem; }
    select.error { border:1px solid map-get($theme-colors, 'danger') !important; color:#000; }
    .drag, .write, .choice     
              { position:relative; margin:0.125rem 0; padding:.125rem .5rem .175rem 40px; display:inline-block; 
                border-top-left-radius: 4px; border-bottom-left-radius: 4px; font-size:$font-size-seventeen; cursor:pointer; }
    .drag     { font-size:$font-size-sm; line-height:1.25; }
    .write    { margin-top:.375rem; padding:0; width:100%; max-width:300px; display:block; }
    label + .write { margin-top:.125rem; }
    .write input { margin:2px 0 1px; border:1px solid #fff; padding-left:40px; width:100%;  }
    .write input:focus { outline:none; }
    .choice   { display:inherit; clear:left; float:left; }
    .drag.image
              { padding:0; }
    .drag.badge-light { max-width:350px; hyphens: auto; }
    .drag::before, .write::before 
              { position:absolute; background:#ccc; top:0; bottom:0; left:0; width:30px; 
                border-top-left-radius: 4px; border-bottom-left-radius: 4px; padding:1px 5px; }
    .write::before { padding:5px; }
    .drag.image::before
              { position:absolute; background:#ccc; top:0; left:0; width:30px; height:30px;
                border-top-left-radius: 0; border-bottom-left-radius: 0; padding:1px 5px; }
    .drag::before 
              { content:url(/img/drag.svg); }
    .write::before 
              { content:url(/img/write.svg); }
    .drag-item { z-index:100; }
    i          { display:inline-block; font-style:normal; }
}
.work.imgdrop .drag:first-of-type { margin-top:0.75rem; }
input[type="text"].form-input.lauftxt {
    background: #fff;
    border-radius: 0;
    border: 1px solid #cdcdcd;
    padding: .025rem;
}
input[type="text"].form-input.lauftxt.pad { padding:.025rem .3rem; margin-bottom:0; }

.nowrap label, .nowrap legend { font-size:$font-size-seventeen; }
.nowrap label { padding-right:.75rem; margin-left:.2rem; white-space:nowrap; }
.nowrap label:last-of-type {padding-right:0; }
#mc legend strong { font-size:$font-size-seventeen;; display:block; font-weight:400; margin-bottom:.75rem; }
.workdrag     { border:2px dashed #ccc; }
.dropover, .bgfull .imgdrop.work:hover { background-color:map-get($theme-colors, 'info'); }
.bgfull .imgdrop.work:hover { background-image:none; }
.couple       { min-height:215px; }
.imgdrop      { min-height:200px; margin:0 0 3px; position:relative; }
.layout-3 .imgdrop { min-height:210px; margin:0 0 3px; }
.imgdrop.einzeiler, .layout-3 .imgdrop.einzeiler { min-height:36px; }
#dd-drop legend { margin-bottom:0.1rem; margin-top:0.5rem; font-size:$font-size-standard; margin-left:15px; }
#dd-drop legend:first-of-type { margin-top:0; }
#dd-drop .work { position:relative; padding:10px; }
#dd-drop .work.einzeiler { padding:0 0 0 40px; }
.bg .imgdrop  { background-repeat:no-repeat; background-origin:content-box, padding-box;
                background-position: right center; background-size: contain; }
.drop_bg      { background-repeat:no-repeat; background-origin:content-box, padding-box;
                background-position: center center; background-size: cover; }
.drop_bg .work { opacity:.8; margin:10px 10px 0; width:auto; }
.drop_bg .row.justify-content-center:last-of-type .work { margin-bottom:10px; }
.bgfull .imgdrop.work  { padding:0; background-repeat:no-repeat; background-size: contain;
background-position:top center; }
.imgdrop span { position:absolute; z-index:1000; top:1px; left:1px; padding:2px 4px; background:#fff; 
                border-top-left-radius: 4px; font-size:$font-size-sm; }
.draganddrop_left + p.figure-caption { margin-bottom:0; }
.workdrag + .workdrag { margin-top:10px; }
.white.box .image img    { width:110px; height:110px; display:inline-block; margin-right:10px;
                           vertical-align:top; }
.image .imgdrop { min-height:110px; } 
.image .work  { width:calc(100% - 120px); display:inline-block;}
.tab-pane     { margin:20px 0 0; padding:0; }

form {
  margin: 0 0 20px 0;
}

form#listen_aufklapp {
  margin-bottom:0;
}

.mce-tinymce.mce-container.mce-panel {
  margin-bottom:1.5rem;
}

#listen_aufklapp select {
  min-width:150px;
}
ul {
  padding:0 0 0 24px;
}
ol { 
  padding:0 0 0 35px; list-style-type: decimal-leading-zero; 
}
.form button.btn, .form a.btn { float:right; margin-top:12px; }
.form button.btn { margin-left:20px; }

.form-control {
  padding:4px 5px 5px; font-size:$font-size-seventeen;
}
label.opt{ font-size:$font-size-seventeen; padding-right:12px; }

small.lbl { padding-bottom:6px; display:block;}
.optgrp input { margin-bottom:0; }

#parent_page, #seite_link_seite, #seite_link_seite p { margin-bottom:.5rem; }

.form-control:focus {
  border-color: #ced4da;
  box-shadow: 0 0 0 .2rem rgba(#ccc,.25);
}

::placeholder {
  color:#bbb;
}

label, form .help-inline.help-error {
  margin-bottom:.25rem; font-size:$font-size-sm;
}

input, textarea, select.form-control {
  margin-bottom:1rem;
}

select {
  font-size:$font-size-sm;
}

fieldset {
  width:100%;
}

input.form-error, input.form-error:focus {
 border:1px solid map-get($theme-colors, 'danger') !important;
}

input[type="password"],
input[type="text"]  { 
  background:#eee; border-radius:0; border-bottom:1px solid #909090; 
  padding:.375rem .375rem .4rem;
}

#suche .card-body p { margin-bottom:9px; }
#suche .card-body p:first-of-type { margin-bottom:6px; }
#search_div {
  width:100%; background:#fff; border:1px solid #909090;
}
#start_search           { float:right; display:inline-block; margin:0; margin:7px 7px 0 0; background:url(/img/zoom-i.svg) no-repeat  0 0; width:24px; height:24px;}
#start_search:hover     { background: url(/img/zoom-a.svg) no-repeat; }
input[type="text"]#search_term            { margin:0; display:inline-block; border-color:transparent; background-color:transparent; width:90%; }

input[type="password"]:focus,
input[type="text"]:focus  { 
  background:#fff; border:1px solid #000; box-shadow:none; outline:none;
}

.pagination li a {
  border:0;
  color:#000;
  padding:6px 10px;
  font-size:1.0625rem;;
} 

.pagination .page-item.active .page-link {
  background-color: #343a40;
  color:#fff;
}

table.cappelli-position-table {
  width: 45px;
  height: 45px;
  tbody {
    td {
      border-bottom: none;
      background: transparent;
    }
    td.position-set {
      background: #343a40;
    }
    input[type="checkbox"] {
      margin-left: -6px;
      margin-top: -6px;
    }
    input[type="checkbox"]:checked:after {
      position: absolute;
      content: " ";
      width: 100%;
      height: 100%;
      background: #336699;
      top: 0;
      left: 0;         
    }
  }
}

table#cappelli-table th a { border:none }

form#filter-cappelli {
  background:#dae1f1;
  margin: 20px 0;
  padding: 20px;
}

#cappelliPageModal {
  .modal-dialog {
    min-width: 800px;
  }
  .modal-body {
    
  }
  #cappelliPageImageContainer {
    #pagePositionIndicator {
      position: absolute;
      border: 1px solid red;
    }
  }
}

span[data-toggle="popover"] {
  cursor: pointer;
}

footer {
    background-color: #fff;
    border-top: $header-border; 
    font-size: $font-size-sm;
    padding:12px 40px 24px;
    ul { display:inline; }
    li { display:inline-block; padding-right:12px; }
    a { color:#000; border-bottom:1px solid #909090; }
    a:hover { color:#000; border-bottom:1px solid #000; text-decoration:none; }
}

.no-gutters {
  margin-right: 0;
  margin-left: 0;

  > .col,
  > [class*="col-"] {
    padding-right: 0;
    padding-left: 0;
  }
}

#datierungstool_filter {
  fieldset.negativ, fieldset.positiv { display:none; }
  select.form-control:not([size]):not([multiple]) { height:auto; }
  select.form-control { margin-bottom:.5rem; }
  .abstand_1-25 { margin-bottom:1.25rem; }
  button.btn { margin-top:1.25rem; margin-left:2px; padding:.125rem .5rem .25rem; }
  #positiv_negativ { display:block; }
}
#datierungstool_filter.negativ {
  fieldset.negativ { display:block; }
}
#datierungstool_filter.positiv {
  fieldset.positiv { display:block; }
}
#datierungstool_filter.positiv.papier {
  #select_glas, label[for="select_glas"] { display:none; }
}
#datierungstool_filter.positiv.glas {
  #select_ausgebleicht, label[for="select_ausgebleicht"], #select_papier_oberflaeche, label[for="select_papier_oberflaeche"], 
  #select_papier_relief, label[for="select_papier_relief"], #select_papier_merkmale, label[for="select_papier_merkmale"] { display:none; }
}
#datierungstool_filter.positiv.other {
  #select_ausgebleicht, label[for="select_ausgebleicht"], #select_papier_oberflaeche, label[for="select_papier_oberflaeche"], 
  #select_papier_relief, label[for="select_papier_relief"], #select_papier_merkmale, label[for="select_papier_merkmale"],
  #select_glas, label[for="select_glas"], #select_farbe, label[for="select_farbe"] { display:none; }
}
#datierungstool_table {
  margin-bottom:2.5rem;
  td { text-align:center; border-left:0; border-right:0; }
  td.text-left { width:70%; }
}
#datierungstool_filter, #datierungstool_table, #gedenkaufzeichnung_table {
  a, a.infos { width:16px !important; height:16px !important; display:inline-block; 
      text-decoration:none; border:0; 
      background-image:url(/img/info-i.svg); background-size:16px 16px; }
  a, a.infos:focus   { outline:none; }
  a, a.infos:hover   { background-image:url(/img/info-a.svg); }
}
#datierungstool_comments {
  div { display:none; }
}
#datierungstool_comments.negativ.papier {
  div.negativ.papier { display:block; }
}
#datierungstool_comments.negativ.glas {
  div.negativ.glas { display:block; }
}
#datierungstool_comments.negativ.film {
  div.negativ.film { display:block; }
}

#gedenkaufzeichnung_table {
  margin-bottom:2.5rem;
  td { border-left:0; border-right:0; }
  td.sm, th { font-size:$font-size-sm; }
  th { font-weight:700; padding:.4rem .35rem; }
  th:focus {outline:none;}
}
#tippModal {
  ul { padding-left:22px; }
  ol { padding-left:35px; }
  li { text-indent:0; padding-left:6px; }
  li abbr { display:inline-block; width:45px; }
}
#training-contenttable {
  th:focus {outline:none;}
}

.tablesorter-default th,.tablesorter-default thead td{border-collapse:collapse;padding:0}
.tablesorter-default tfoot td,.tablesorter-default tfoot th{border:0}
.tablesorter-default .header,.tablesorter-default .tablesorter-header{background-image:url(data:image/gif;base64,R0lGODlhFQAJAIAAACMtMP///yH5BAEAAAEALAAAAAAVAAkAAAIXjI+AywnaYnhUMoqt3gZXPmVg94yJVQAAOw==);background-position:center right;background-repeat:no-repeat;cursor:pointer;white-space:normal;padding:4px 20px 4px 4px}
.tablesorter-default thead .headerSortUp,.tablesorter-default thead .tablesorter-headerAsc,.tablesorter-default thead .tablesorter-headerSortUp{background-image:url(data:image/gif;base64,R0lGODlhFQAEAIAAACMtMP///yH5BAEAAAEALAAAAAAVAAQAAAINjI8Bya2wnINUMopZAQA7);border-bottom:#000 2px solid}.tablesorter-default thead .headerSortDown,.tablesorter-default thead .tablesorter-headerDesc,.tablesorter-default thead .tablesorter-headerSortDown{background-image:url(data:image/gif;base64,R0lGODlhFQAEAIAAACMtMP///yH5BAEAAAEALAAAAAAVAAQAAAINjB+gC+jP2ptn0WskLQA7);border-bottom:#000 2px solid}
.tablesorter-default thead .sorter-false{background-image:none;cursor:default;padding:4px}
.tablesorter-default tfoot .tablesorter-headerAsc,.tablesorter-default tfoot .tablesorter-headerDesc,.tablesorter-default tfoot .tablesorter-headerSortDown,.tablesorter-default tfoot .tablesorter-headerSortUp{border-top:#000 2px solid}
.tablesorter-default tbody>tr.even:hover>td,.tablesorter-default tbody>tr.hover>td,.tablesorter-default tbody>tr.odd:hover>td,.tablesorter-default tbody>tr:hover>td{background-color:#fff;color:#000}
.tablesorter-default .tablesorter-processing{background-position:center center!important;background-repeat:no-repeat!important;background-image:url(data:image/gif;base64,R0lGODlhFAAUAKEAAO7u7lpaWgAAAAAAACH/C05FVFNDQVBFMi4wAwEAAAAh+QQBCgACACwAAAAAFAAUAAACQZRvoIDtu1wLQUAlqKTVxqwhXIiBnDg6Y4eyx4lKW5XK7wrLeK3vbq8J2W4T4e1nMhpWrZCTt3xKZ8kgsggdJmUFACH5BAEKAAIALAcAAAALAAcAAAIUVB6ii7jajgCAuUmtovxtXnmdUAAAIfkEAQoAAgAsDQACAAcACwAAAhRUIpmHy/3gUVQAQO9NetuugCFWAAAh+QQBCgACACwNAAcABwALAAACE5QVcZjKbVo6ck2AF95m5/6BSwEAIfkEAQoAAgAsBwANAAsABwAAAhOUH3kr6QaAcSrGWe1VQl+mMUIBACH5BAEKAAIALAIADQALAAcAAAIUlICmh7ncTAgqijkruDiv7n2YUAAAIfkEAQoAAgAsAAAHAAcACwAAAhQUIGmHyedehIoqFXLKfPOAaZdWAAAh+QQFCgACACwAAAIABwALAAACFJQFcJiXb15zLYRl7cla8OtlGGgUADs=)!important}.tablesorter-default tr.odd>td{background-color:#dfdfdf}.tablesorter-default tr.even>td{background-color:#efefef}.tablesorter-default tr.odd td.primary{background-color:#bfbfbf}.tablesorter-default td.primary,.tablesorter-default tr.even td.primary{background-color:#d9d9d9}
.tablesorter-default tr.odd td.secondary{background-color:#d9d9d9}
.tablesorter-default td.secondary,.tablesorter-default tr.even td.secondary{background-color:#e6e6e6}
.tablesorter-default tr.odd td.tertiary{background-color:#e6e6e6}
.tablesorter-default td.tertiary,.tablesorter-default tr.even td.tertiary{background-color:#f2f2f2}
.tablesorter-default>caption{background-color:#fff}
.tablesorter-default .tablesorter-filter-row{background-color:#eee}
.tablesorter-default .tablesorter-filter-row td{background-color:#eee;border-bottom:#ccc 1px solid;line-height:normal;text-align:center;-webkit-transition:line-height .1s ease;-moz-transition:line-height .1s ease;-o-transition:line-height .1s ease;transition:line-height .1s ease}
.tablesorter-default .tablesorter-filter-row .disabled{opacity:.5;cursor:not-allowed}
.tablesorter-default .tablesorter-filter-row.hideme td{padding:2px;margin:0;line-height:0;cursor:pointer}
.tablesorter-default .tablesorter-filter-row.hideme *{height:1px;min-height:0;border:0;padding:0;margin:0;opacity:0}
.tablesorter-default input.tablesorter-filter,.tablesorter-default select.tablesorter-filter{width:95%;height:auto;margin:4px auto;padding:4px;background-color:#fff;border:1px solid #bbb;color:#333;-webkit-box-sizing:border-box;-moz-box-sizing:border-box;box-sizing:border-box;-webkit-transition:height .1s ease;-moz-transition:height .1s ease;-o-transition:height .1s ease;transition:height .1s ease}
.tablesorter .filtered{display:none}.tablesorter .tablesorter-errorRow td{text-align:center;cursor:pointer;background-color:#e6bf99}

.progress { font-size:.875rem; height:1.1rem; margin-top:5px; }
.progress-bar { color:#000; }
.progress_value { position:absolute;left:0;right:0; text-align:center; }
.result_children { display:none; margin-bottom:1rem; }
.result_children span { display:inline-block; padding-left:25px;}
.result_children .row { margin-bottom:2px; }
#nav-bilanz, #studentReportsModalWindow {
  .subtitle { cursor:pointer; }
  .plusminus     { margin: 1px 10px 12px 0; width:15px; height:15px; float:left; }
  .plusminus::before  { content: url(/img/minus-i.svg);  }
  .subtitle:hover .plusminus::before  { content: url(/img/minus-a.svg); }
  .collapsed.plusminus::before { content: url(/img/plus-i.svg); }
  .subtitle:hover .collapsed.plusminus::before { content: url(/img/plus-a.svg); }
}
#studentReportsModalWindow { max-width:1024px !important; }
#nav-kursverwaltung {
  hr { border-color:#ccc; }
}
#iCourseIdTeacher { max-width:100%; }
#registered_courses a, #course_students a.minusicon { margin-left:12px; text-decoration:none; border:0; }
.minus { display:inline-block; width:16px; height:16px; margin-top:2px; cursor:pointer; }
.minus::before { content: url(/img/minus-i.svg);  }
.minus:hover::before  { content: url(/img/minus-a.svg); }


/* BEGIN smaller than tablets */
@media (min-width: 320px) and (max-width: 767px) { 
  header                { height:auto; }
  #mainnav ul#firstlevel { padding:5px 0 0 0; font-size:17px !important; margin-top:80px; }
  #navigation           { margin-right:0; }
  li.mainnav-li         { padding-left:0 !important; padding-right:14px; }
  #mainnav .subnav      { top:132px; left:-14px; padding: 0 14px; }
  #servicenav           { display:block; width:100%; right:0;   }
  #servicenav ul        { float:left; }
  #servicenav #profile-filter { margin:0 0 6px; position:absolute; top:30px; }
  header #dark_overlay, header #white_overlay_top, header #white_overlay_content
                        { display:none !important; } 
}

@media (max-width: 480px) { 
  #mainnav .subnav      { top:155px; right:-56px; left:-14px;  padding: 0 14px 0 14px; }
}
/* END smaller than tablets */

@media (min-width: 576px) {
  .oneandahalf-offset   { padding:0 25px; }
  .oneandahalf          { padding:5px 10px 0 25px; }
  #intro .container     { background:$bg-adfontes }
  #recherche.home .container   { background:$bg-recherche }
  #loginModal .modal-dialog, #glossaryItemModal .modal-dialog {
      -webkit-transform: translate(0,0);
      -o-transform: translate(0,0);
      transform: translate(0,0);
      top: 5%;
      margin: 0 auto;
  }
}

@media (min-width: 768px) {
 #logo                  { position:absolute; top:0; left:40px; z-index:1000; }
  header                { height:155px; }
  header #white_overlay_top { height:155px; }
  header #dark_overlay  { background:rgba(255,255,255,1); top:155px; }
  #navigation           { margin:0 10px 0 0; }
  #mainnav              { margin-top:106px; }
  #mainnav .subnav      { left:48px; top:153px; right:-14px; } 
  #servicenav           { display:block; min-width:500px; left:auto; right:0; }
  #mainnav              { display:block; }  

  ul#firstlevel          { min-width:610px; }
  .modal-md {
    max-width:600px;
  }

}

@media (min-width: 768px) AND (max-width: 991px) {
  #profile-filter      { margin-left:0; margin-right:20px; }
}

@media (max-width: 991px) { 
  header #white_stripe  { display:none !important; }
  #mainnav ul#firstlevel { font-size:20px; }
  .cntnt.col-lg-7, .cntnt.col-lg-5, .cntnt.col-lg-6 { margin-bottom:20px; }
}

@media (min-width: 992px) {
  .oneandahalf-offset   { padding:0 25px 0 147px; }
  header                { height:105px; }
  header #white_overlay_top { height:105px; }
  header #dark_overlay  { background:rgba(0,0,0,0.3); top:0; }
  #navigation           { margin:0 40px 0 0; }
  #logo                 { margin-left:40px; }
  #mainnav              { margin-top:56px; }
  #mainnav .subnav      { left:48px; top:103px; right:-14px; } 
  #servicenav           { min-width:auto; left:0; right:0; }
  .vpager li            { margin-left:4px; }
  .work {
     .drag              { font-size:$font-size-seventeen; line-height:1.3; }
     .drag::before,
     .drag.image::before { padding:5px; }
     .drag.smlfont      { font-size:$font-size-sm; line-height:1.25; }
     .drag.smlfont::before,
     .drag.image.smlfont::before { padding:1px 5px; }
  }
}

@media (min-width: 1200px) {
  .oneandahalf        { padding:5px 10px 0 25px; width:134px; }
  .letter .container, #intro.letter .container {
    background:transparent url(/img/letter/letter_1.svg) no-repeat 100% 40%;
    background-size: 560px 560px;
    min-height:545px;
  }
  .letter_1 .container, #intro.letter_1 .container  { background-image: url(/img/letter/letter_1.svg); }
  .letter_2 .container, #intro.letter_2 .container  { background-image: url(/img/letter/letter_2.svg); }
  .letter_3 .container, #intro.letter_3 .container  { background-image: url(/img/letter/letter_3.svg); }
  .letter_4 .container, #intro.letter_4 .container  { background-image: url(/img/letter/letter_4.svg); }
  .letter_5 .container, #intro.letter_5 .container  { background-image: url(/img/letter/letter_5.svg); }
  .letter_6 .container, #intro.letter_6 .container  { background-image: url(/img/letter/letter_6.svg); }
  .letter_7 .container, #intro.letter_7 .container  { background-image: url(/img/letter/letter_7.svg); }
  .letter_8 .container, #intro.letter_8 .container  { background-image: url(/img/letter/letter_8.svg); }
  .letter_9 .container, #intro.letter_9 .container  { background-image: url(/img/letter/letter_9.svg); }
  .letter_10 .container, #intro.letter_10 .container { background-image: url(/img/letter/letter_10.svg); }


  .alert ul { padding-left:14%; padding-right:14%; }
  #loginModal .modal-dialog, #glossaryItemModal .modal-dialog {
      -webkit-transform: translate(0,-50%);
      -o-transform: translate(0,-50%);
      transform: translate(0,-50%);
      top: 50%;
  }
}

@media (min-width: 1430px) {
  .container { max-width: 1340px; }
}