/* Font sizes */
$font-size-standard:    1.1875rem; // 19px
$font-size-mainnav:     1.3125rem; // 21px
$font-size-seventeen:   1.0625rem; // 17px
$font-size-xlg:         1.5rem; // 24px for 400
$font-size-xlg-bold:    1.5625rem; // 25px for 700

/* Background colors */
$bg-adfontes:   #fbece5;
$bg-grey:       #f1f2f4;
$bg-recherche:  #f0efe4;
$bg-training:   #ebf3f1;
$bg-ressourcen: #eff2f9;
$bg-tutorium:   #fbf8db;

/* Link colors */
$co-adfontes:   #7e401c;
$co-recherche:  #6f6d56;
$co-training:   #62676c;
$co-ressourcen: #243a6c;
$co-tutorium:   #857f4a;

/* Header border */
$header-border: 2px solid #000;